.custom-field-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .custom-field-table th,
  .custom-field-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .custom-field-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .custom-field-table th:first-child,
  .custom-field-table td:first-child {
    border-left: none; /* Remove the left border for the first column */
  }
  
  .custom-field-table th:last-child,
  .custom-field-table td:last-child {
    border-right: none; /* Remove the right border for the last column */
  }