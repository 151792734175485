.react-tel-input .form-control {
    width: 100%;
    height : 60px;
    border-radius: 0.5rem;
    color:#4E5664;
}

.react-tel-input .form-control:focus {
    border: 2.5px solid black; 
    outline: none; 
}
.react-tel-input .flag-dropdown {
    border-radius: 6px 0 0 6px;
    border-right: none;
}
.react-tel-input .form-control:focus + .flag-dropdown {
    border: 2.5px solid black;
    border-right: none;
}
